import React from "react"
import { Component } from "react";

class FaqAccordion extends Component{

    data = this.props.data;

    CardsToggle(cards, currentItem){
        for (let i = 0; i < cards.length; i++) {
            let item = cards[i].querySelector('.accordion');
            let panel = cards[i].querySelector(".panel");

            if (item !== currentItem){
                if (item.classList.contains("active")){
                    item.classList.remove("active")
                }

                panel.style.maxHeight = null;
            } else {
                item.classList.toggle("active");

                if (panel.style.maxHeight){
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            }
        }
    }

    componentDidMount(){
        let cards = document.querySelectorAll(".accordion-card");

        for (let i = 0; i < cards.length; i++) {
            let item = cards[i].querySelector('.accordion');

            item.addEventListener("click", () => {
                this.CardsToggle(cards, item);
            });
        }
    }

    render(){
        return (
            <div className="faq-accordion">
                {
                    this.data?.questions?.nodes.map((node) => (
                        node?.faqs?.questions.map((faq) => (
                            <div className="accordion-card rounded bg-gray-100 mb-3">
                                <button class="accordion p-4">
                                    <h4 className="accordion-header text-primary-dark font-weight-400">{faq.title}</h4>
                                </button>
                                <div class="panel">
                                    <p className="mb-0 mt-3">{ faq.answer.split("\n").map((par => ( <p>{par ?? ""}</p>))) }</p>
                                </div>
                            </div>
                    )))) ?? ''
                }
            </div>
        )
    }
}

export default FaqAccordion
