import React from "react"
import {Container} from "react-bootstrap"
import SEO from "../components/seo"
import ValuationCTA from "../components/valuationCta";
import FaqAccordion from "../components/FaqAccordion";
import Layout from "../components/layout";
import {graphql} from "gatsby";

const FaqsPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "FAQ"}}>
            {console.log(data)}
            <SEO title={data?.questions?.nodes[0]?.metaForPage?.title ?? "Frequently Asked Questions"} description={data?.questions?.nodes[0]?.metaForPage?.description ?? "These are our frequently asked questions, a list of commonly asked questions and answers for you to read. If you don't see what you need, please contact us."}/>

            <Container className="slice">
                <h1 className="text-center font-weight-800 mb-5">Frequently Asked Questions</h1>

                <FaqAccordion data={data}></FaqAccordion>

            </Container>

            <ValuationCTA></ValuationCTA>
        </Layout>
    )
}

export const data = graphql`
    query FAQ{
        questions: allWpPage(filter: {
            slug: {
                eq: "faq"
            }
        }
        ){
            nodes {
                faqs {
                    questions {
                      answer
                      title
                      more
                    }
                }
                metaForPage {
                    title
                    description
                }
            }
        }
    }
`

export default FaqsPage
